.primary-text {
  color: var(--primaryColor);
}
.bgColor-primary {
  background-color: var(--primaryColor);
}
.para-text {
  color: rgba(33, 38, 77, 0.54);
}
.bg-color {
  background-color: var(--bgColor);
}

.logo-text {
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: var(--secondaryColor);
  text-transform: uppercase;
}
.section-title {
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.navbar-main .nav-item .nav-link {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--secondaryColor);
}
.navbar-main .nav-link.active,
.navbar-main .nav-link.show,
.navbar-main .nav-link:focus {
  border-bottom: 2px solid var(--primaryColor) !important;
  color: var(--primaryColor) !important;
  font-weight: 600;
}

.languages .languages-btn {
  color: var(--secondaryColor);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
}
.languages .dropdown-menu {
  width: 240px !important;
}
.languages .dropdown-menu li {
  padding-block: 5px;
}
.login-btn {
  color: #fff;
  font-family: var(--font);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  height: 56px;
  background-color: #3fd898;
  border-radius: 18px;
}
.login-btn:hover {
  color: var(--primaryColor) !important;
  border: 1px solid var(--primaryColor);
}
.login-btn:hover span {
  color: var(--primaryColor) !important;
}
/* Homepage */

.home-header .header-title {
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px; /* 125% */
  width: 45%;
}

.rest-card,
.food-card,
.features-card,
.services-card {
  border-radius: 20px;
}
.rest-card h5,
.food-card h5,
.features-card h5,
.services-card h5 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #21264c;
}

.ratings i {
  color: #f6921e;
  font-size: 18px;
}

.ratings i {
  font-size: 16px;
  color: #f6921e;
}
.reviews-main .carousel-control-next-icon {
  position: absolute;
  bottom: 10px;
  border: 1px solid var(--primaryColor);
  border-radius: 10px;
  right: 10px;
  background-image: url(./../public/assets/arrow2.png) !important;
}
.reviews-main .carousel-control-next {
  width: 40px;
}
.reviews-main .carousel-control-prev {
  position: static !important;
}
.reviews-main .carousel-control-prev-icon {
  position: absolute;
  bottom: 10px;
  border: 1px solid var(--secondaryColor);
  border-radius: 10px;
  right: 15%;
  background-image: url(./../public/assets/arrow1.png) !important;
}
.about .btn-read-more {
  height: 56px;
  padding: 16px 22px 16px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 18px;
  background-color: var(--primaryColor);
  color: white;
}
.search-input {
  padding: 15px;
  font-size: 18px;
  border-radius: 18px 0px 0px 18px !important;
  padding-left: 70px;
}
.btn-search,
.btn-search:hover {
  height: 100%;
  background-color: var(--primaryColor);
  color: white;
  font-size: 18px;
  width: 140px;
}
.search-icon {
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 99;
}
.contact-content .contact-title {
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 155%; /* 74.4px */
}
.contact-content p {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 29.99px; /* 149.95% */
}
.contact-content .contact-btn {
  background-color: var(--primaryColor);
  border-radius: 15px;
  padding: 15px;
  color: white;
  border: none;
}
/* Foooter style */
footer.footer {
  background-color: #7be4b8;
  color: rgba(33, 38, 76, 0.7);
}
.footer .links .title,
.footer .contact .title,
.footer .social .title {
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--secondaryColor);
}
.footer .links a,
.footer .contact a,
.footer .social a {
  color: rgba(33, 38, 76, 0.7);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.icons .icon-1 i {
  color: white;
  font-size: 24px;
}
@media all and (max-width: 1200px) {
  .home-header .header-title,
  .section-title {
    font-size: 36px;
    line-height: 38px;
  }
  .about .content {
    width: 100% !important;
  }
}
@media all and (max-width: 991px) {
  .home-header .header-title,
  .section-title {
    font-size: 26px;
    line-height: 32px;
    width: 80% !important;
  }
}

@media all and (max-width: 620px) {
  .navbar-brand.lg img {
    width: 25px;
  }
  .navbar-brand.lg .logo-text {
    font-size: 16px;
  }
  .login-btn {
    font-size: 12px;
    height: 40px;
  }
}
