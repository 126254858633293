@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
  --primaryColor: #3fd898;
  --secondaryColor: #21264c;
  --bgColor: #f9f9f9;
  --font: "poppins";
  --textColor: rgba(33, 38, 77, 0.54);
}

* {
  margin: 0;
  outline: none;
  padding: 0;
  box-sizing: border-box;
  color: var(--secondaryColor);
}
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: poppins;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
